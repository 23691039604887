var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - List Users" } }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Users")
                  ]),
                  _c(
                    "gov-grid-row",
                    [
                      _c(
                        "gov-grid-column",
                        { attrs: { width: "two-thirds" } },
                        [
                          _c(
                            "ck-table-filters",
                            { on: { search: _vm.onSearch } },
                            [
                              _c(
                                "gov-form-group",
                                [
                                  _c(
                                    "gov-label",
                                    { attrs: { for: "filter[first_name]" } },
                                    [_vm._v("First name")]
                                  ),
                                  _c("gov-input", {
                                    attrs: {
                                      id: "filter[first_name]",
                                      name: "filter[first_name]",
                                      type: "search"
                                    },
                                    model: {
                                      value: _vm.filters.first_name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "first_name", $$v)
                                      },
                                      expression: "filters.first_name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "extra-filters" },
                                [
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        { attrs: { for: "filter[last_name]" } },
                                        [_vm._v("Last name")]
                                      ),
                                      _c("gov-input", {
                                        attrs: {
                                          id: "filter[last_name]",
                                          name: "filter[last_name]",
                                          type: "search"
                                        },
                                        model: {
                                          value: _vm.filters.last_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "last_name",
                                              $$v
                                            )
                                          },
                                          expression: "filters.last_name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        { attrs: { for: "filter[email]" } },
                                        [_vm._v("Email")]
                                      ),
                                      _c("gov-input", {
                                        attrs: {
                                          id: "filter[email]",
                                          name: "filter[email]",
                                          type: "search"
                                        },
                                        model: {
                                          value: _vm.filters.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.filters, "email", $$v)
                                          },
                                          expression: "filters.email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        { attrs: { for: "filter[phone]" } },
                                        [_vm._v("Phone number")]
                                      ),
                                      _c("gov-input", {
                                        attrs: {
                                          id: "filter[phone]",
                                          name: "filter[phone]",
                                          type: "search"
                                        },
                                        model: {
                                          value: _vm.filters.phone,
                                          callback: function($$v) {
                                            _vm.$set(_vm.filters, "phone", $$v)
                                          },
                                          expression: "filters.phone"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        {
                                          attrs: { for: "filter[highest_role]" }
                                        },
                                        [_vm._v("Highest permission level")]
                                      ),
                                      _c(
                                        "gov-hint",
                                        {
                                          attrs: { id: "filter[highest_role]" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Please note - you cannot filter by Permission Level and\n                    Service/Organisation at the same time.\n                  "
                                          )
                                        ]
                                      ),
                                      _c("gov-select", {
                                        attrs: {
                                          id: "filter[highest_role]",
                                          name: "filter[highest_role]",
                                          options: _vm.roles
                                        },
                                        model: {
                                          value: _vm.filters.highest_role,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "highest_role",
                                              $$v
                                            )
                                          },
                                          expression: "filters.highest_role"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        {
                                          attrs: {
                                            for: "filter[at_organisation]"
                                          }
                                        },
                                        [_vm._v("Organisation")]
                                      ),
                                      _vm.loadingOrganisations
                                        ? _c("ck-loader")
                                        : _c("gov-select", {
                                            attrs: {
                                              id: "filter[at_organisation]",
                                              name: "filter[at_organisation]",
                                              options: _vm.organisations
                                            },
                                            model: {
                                              value:
                                                _vm.filters.at_organisation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "at_organisation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filters.at_organisation"
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        {
                                          attrs: { for: "filter[at_service]" }
                                        },
                                        [_vm._v("Service")]
                                      ),
                                      _vm.loadingServices
                                        ? _c("ck-loader")
                                        : _c("gov-select", {
                                            attrs: {
                                              id: "filter[at_service]",
                                              name: "filter[at_service]",
                                              options: _vm.services
                                            },
                                            model: {
                                              value: _vm.filters.at_service,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "at_service",
                                                  $$v
                                                )
                                              },
                                              expression: "filters.at_service"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm.auth.canAdd("user")
                        ? _c(
                            "gov-grid-column",
                            { attrs: { width: "one-third" } },
                            [
                              _c(
                                "gov-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    success: "",
                                    expand: ""
                                  },
                                  on: { click: _vm.onAddUser }
                                },
                                [_vm._v("Add user")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("ck-resource-listing-table", {
                    ref: "usersTable",
                    attrs: {
                      uri: "/users",
                      params: _vm.params,
                      "default-sort": "first_name",
                      columns: [
                        {
                          heading: "First name",
                          sort: "first_name",
                          render: function(user) {
                            return user.first_name
                          }
                        },
                        {
                          heading: "Last name",
                          sort: "last_name",
                          render: function(user) {
                            return user.last_name
                          }
                        },
                        {
                          heading: "Highest permission level",
                          sort: "highest_role",
                          render: function(user) {
                            return _vm.auth.displayHighestRole(user.roles)
                          }
                        },
                        {
                          heading: "Phone number",
                          render: function(user) {
                            return user.phone
                          }
                        }
                      ],
                      "view-route": function(user) {
                        return {
                          name: "users-show",
                          params: { user: user.id }
                        }
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }